@import url('https://fonts.googleapis.com/css2?family=Noto+Emoji:wght@500&family=Sofia+Sans:wght@300;400;500;600;700;800;900&display=swap');

.body {
  --body: #f4f5f7;
  --contents-bg: #f4f5f7;
  --main-color: white;
  --accent: #ffffff;
  --bs-blue-rgb:13, 110, 253;
  --bs-blue: rgb(var(--bs-blue-rgb));
  --bs-indigo: rgb(102, 16, 242);
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-white-secondary: #e9ecef;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: var(--bs-blue);
  --color-primary:var(--bs-primary);
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-info-color:#EFEFEE;
  --bs-warning: #ffc107;
  --bs-danger: #da4c5a;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb:var(--bs-blue-rgb);
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-font-sans-serif: 'Sofia Sans', sans-serif;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --black-color: #15232e;
  --black-color-2: #1f2935;
  --black-color-3: #2d3743;
  --black-color-4: #333e4c;
  --black-color-control-1: #141f27;
  --black-color-control-2: #1d2c38;
  --black-color-control-light: #333e4c;
  --color-gray: var(--grey-color-3);
  --grey-color-1: #8697a8;
  --grey-color-2: #a6b2bf;
  --grey-color-3: #273546;
  --grey-color-4: #b3c0ce;
  --grey-color-5: #cfcfd1;
  --grey-color-6: #737781;
  --grey-color-7: #c4c4c4;
  --text-primary-color:var(--grey-color-3);
  --bs-body-color: var(--grey-color-3);
  --grey-light-color-1: #f8fafb;
  --grey-light-color-2: #eef3f5;
  --grey-light-color-3: #eaedf0;
  --grey-light-color-4: #eff4f8;
  --grey-light-color-5: #f4f4f9;
  --grey-light-color-6: #e5e5e5;
  --color-light: #747474;
  --color-lighten: #A0A0A0;
  --color-lighter: #F8F9FB;
  --color-lighten-x: #5d658c;
  --color-info-rgba:0,170,255;
  --light-gray-rgba: 173,181,217;
  --border-color: #F1F2F6;
  --border-light: #E3E6EF;
  --border-deep: #c6d0dc;
  --text-color: #666d92;
  --white-color: #fff;
  --blue-color: var(--bs-blue);
  --blue-color-a15: rgba(var(--bs-blue-rgb),.15);
  --blue-color-2: #0037fa;
  --blue-color-control-1: #2c91f7;
  --blue-color-control-2: #0f3ad8;
  --teal-color: #22cce2;
  --red-color: #fd594a;
  --red-color-2: #ff3d57;
  --green-color: #09b66d;
  --orange-color-1: #ff8a48;
  --orange-color-2: #fdbf5e;
  --t-base: 0.18s ease-in-out;
  --t-smooth: cubic-bezier(.215,.61,.355,1);
  --t-elastic: 0.3s cubic-bezier(0.68,-0.55,0.265,1.55);
  --font-size-base: 15px;
  --font-family-default: 'Sofia Sans', sans-serif;
  --font-family-secondary: "Comfortaa",sans-serif;
  --font-family-third: "Rounded Mplus 1c";
  --font-icons: "Material Icons";
  --sidebar-width: 15rem;
  --sidebar-full-width: 15rem;
  --sidebar-collapse-width: 64px;
  --header-height: 4.2667rem;
  --subheader-height: 4.2rem;
  --order-tabs-height: 4.3333rem;
  --order-tabs-offset-bottom: 2.6667rem;
  --container-width: 100%;
  --container-width-xl: 1200px;
  --container-gutter: 30px;
  --container-gutter-sm: 24px;
  --column-gutter: 15px;
  --column-gutter-md: 12px;
  --column-gutter-sm: 10px;
  --column-gutter-xs: 7px;
  --gutter-bottom-xl: 2rem;
  --gutter-bottom-lg: 1.6rem;
  --gutter-bottom-sm: 1.3333rem;
  --gutter-bottom-xs: 14px;
  --radius-base: 5px;
  --radius-lg: 10px;
  --tooltip-arrow-size: 12px;
  --card-border-radius: 10px;
  --card-gutter-x: 2.6667rem;
  --card-credit-width: 320px;
  --input-gutter-x: 17px;
  --card-shadow: 0px 2px 4px rgba(169,194,209,.05),0px 12px 16px rgba(169,194,209,.1);
  --background-primary-color: var(--grey-light-color-1);
  --border-radius: 10px;
  --bs-border-form-color: var(--bs-primary);
  --bs-border: #D8E0FF;
  --font-2rem:2rem;
  --font-1rem:1rem;
  --font-1-5rem:1.5rem;
  --font-2-5rem:2.5rem;
  --header-bg: white;
  --main-content: #f4f6fd;
  --title: var(--color-gray);
  --title-primary: var(--color-gray);
  --title-secondary: var(--grey-color-1);
  --btn-icon-border-radius:100px;
  --border: #ebf0f3;
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

html{
  height: 100%;
  width: 100%;
  position: relative;
}
.body .dropdown-item{
  --bs-dropdown-link-active-bg: var(--white-color);
  --bs-dropdown-link-active-color:var(--bs-body-color)
}
.body .dropdown-menu{
  border-color: transparent;
  box-shadow: 0px 0px 10px 5px rgba(var(--bs-primary-rgb), 0.08);
}
.transition-all {
  -webkit-transition-property: all!important;
  transition-property: all!important;
  -webkit-transition-timing-function: cubic-bezier(0.4,0,0.2,1)!important;
  transition-timing-function: cubic-bezier(0.4,0,0.2,1)!important;
  -webkit-transition-duration: 400ms!important;
  transition-duration: 400ms!important;
}
.title-primary{
  color: var(--title-primary);
}
.title-secondary{
  color: var(--title-secondary);
}

::-webkit-scrollbar {
  display: none;
}